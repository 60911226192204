import React, { useEffect, useState } from "react";
import "../stylesTMS/styles-tms.css";
import { useTMSStore } from "../../../../../../store/tms-store";
import { getLoadsData } from "./services/get-loads-data";
import { TMSFiltersCarga } from '../screen/components/tms-filter-carga';
import { TMSSearchText } from "../screen/components/tms-filters-search-text";
import { TMSFiltersTransport } from '../screen/components/tms-filter-transport';
import { TMSFiltersOvTrn } from '../screen/components/tms-filter-ovtrn';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Table, Col,Pagination, Row, Tag } from "antd";
import { ArrowLeftOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../stylesTMS/styles.css";
import { TMSShipmentScreen } from "./TMSShipmentScreen";
import { useLazyQuery } from "@apollo/client";
import { fetchOrders, fetchSectors, fetchDistributionCenters, fetchSearchConsecutive } from "../../../screens/TMS-Dynamics/queries/TMSqueries";
import moment from "moment";
import "moment/locale/es";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { getColumnSearchPropsText } from "../../../../../completed-routes/ui/screens/UseCases";
import localStorageService from '../../../../../../services/localStorageService';
import e from "cors";
import { Details } from "@mui/icons-material";
const CenterUser =  localStorageService.get("selectedWarehousesname");

const scheme = proto => window.location.protocol === "https:" ? `${proto}s` : proto;

export const TMSLoadScreen = (props) => {
  const history = useHistory();
  const [load, setLoads] = useState();
  const [loadData, setLoadsDataRefresh] = useState();
  const [dataFilterLoad, setDataFilterLoad] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterUm, setFilterUm] = useState("");
  const [filterTransport, setFilterTransport] = useState("NAVI");
  const [filterTransportOvTrn, setFilterTransportOvTrn] = useState("");
  const [selectedPageSize, setSelectedPageSize] = useState(30);
  const [selectedPageId, setSelectedPageId] = useState(1);
  const [selecteLoad, setSelecteLoad] = useState();
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchVisibleOvTrn, setSearchVisibleOvTrn] = useState(false);
  const [searchVisibleConsecutiveUm, setSearchVisibleConsecutiveUm] = useState(false);
  const [fromDate, setFromDate] = useState(localStorage.getItem("range-date-tms-1"));
  const [toDate, setToDate] = useState(localStorage.getItem("range-date-tms-2"));
  const [selectedWarehousename, setSelectedWarehousename] = useState(JSON.parse(localStorageService.get("selectedWarehousesname"))?.warehousesname);
  const setLoadsData = useTMSStore((state) => state.setLoadsData);
  const warehousesname = useTMSStore((state) => state.warehousesname);
  const searchText = useTMSStore((state) => state.searchText);
  const initLoading = useTMSStore((state) => state.initLoading);
  const startLoading = useTMSStore((state) => state.startLoading);
  const startInitLoading = useTMSStore((state) => state.startInitLoading);
  const seeDispatched = useTMSStore((state) => state.seeDispatched);
  const seeDelivery = useTMSStore((state) => state.seeDelivery);
  const transport = useTMSStore((state) => state.transport); 
  const ovTrn = useTMSStore((state) => state.ovTrn);
  const setSearchTextOvTrn = useTMSStore((state) => state.setSearchTextOvTrn);
  const [searchFilterOvTrn, setSearchFilterOvTrn] = useState("");
  const setSearchText = useTMSStore((state) => state.setSearchText);
  const [searchFilter, setSearchFilter] = useState("");
  const [isBlankActive, setIsBlankActive] = useState(false);
  const [isBlankActiveOvtm, setIsBlankActiveOvtm] = useState(false);
  const [valorBusqueda, setValorBusqueda] = useState('');
  

  const [getOrderRunning, dataOrderRunning] = useLazyQuery(fetchOrders, {
    fetchPolicy: "no-cache",
  });
  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });

  const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(fetchDistributionCenters,{
    fetchPolicy: "no-cache",
  });

  const[getSearchConsecutive, dataSearchConsecutive] = useLazyQuery(fetchSearchConsecutive, { 
    fetchPolicy: "no-cache",
  });
  
  
  useEffect(()=>{
    startInitLoading();
  },[]);
  
  
  useEffect(() => {
    
    const warehousesname = JSON.parse(localStorageService.get("selectedWarehousesname"))?.warehousesname;
    setSelectedWarehousename(warehousesname);
    if(dataOrderRunning.data?.ordersTable?.length >= 0 && dataSectors.data?.sectors?.length >= 0 && dataDistributionCenters.data?.distributionCenters?.length >= 0){
      if(load?.length !== 0){
        getTMSLoad(dataOrderRunning.data?.ordersTable, warehousesname, selectedPageSize, selectedPageId, filter, filterTransport, filterTransportOvTrn);
      }
    }
    if(fromDate !== localStorage.getItem("range-date-tms-1")){
      setFromDate(localStorage.getItem("range-date-tms-1"))
    }
    if(toDate !== localStorage.getItem("range-date-tms-2")){
      setToDate(localStorage.getItem("range-date-tms-2"))
    }
}, [props.flag,dataOrderRunning, dataSectors, dataDistributionCenters, selectedPageSize, selectedPageId]);

useEffect(()=>{
  getTMSLoad();
},[warehousesname,
  fromDate,
  toDate,
  searchText,
  seeDispatched,
  seeDelivery,
  transport,
  ovTrn]);


const getTMSLoad = async (Ordertable,Center,pageSize,pageId) => {
  startLoading();
    setLoading(true);
    const orderConsecutiveShippingList = dataOrderRunning.data?.ordersTable?.filter(order => order.consecutiveShipping).map(order => order.consecutiveShipping);

    const endpoint = process.env.REACT_APP_FUNCTION_GET_LOAD_FULL_DATA;

    const bodyLoad = {
      tenantUrl: "navitrans.com.co",
      clientId: "97a8cc5c-65a7-40ac-b1b8-4c9f50e2bc3b",
      clientSecret: "Iwn8Q~aK8zD83KUsmXUABb8l_eRJ6G~oOzYCgcRB",
      tenant: "https://test-navitrans.sandbox.operations.dynamics.com",
      environment: "test-navitrans",
      loadId: searchText === undefined ? "" : searchText,
      inventLocationId: warehousesname?.length === 0 ? "" : warehousesname,
      pageSize: pageSize === undefined ? selectedPageSize : pageSize,
      pageId: pageId === undefined ? 1 : pageId,
      isDescending: true,
      fromDate: fromDate ?? "",
      toDate: toDate ?? "",
      seeDispatched: 0,
      filterByDispatchDate: false,
      transport: transport === undefined ? "" : transport,
      ovTrn: ovTrn === undefined ? "" : ovTrn,
    };

    try {
      await axios.post(endpoint, bodyLoad).then(async(response) => {
        const _data = response.data.response;
        const count = response.data.count;

       let consecutive = _data.map((item)=>{
          return({
            LoadId: item.LoadId
          })  
        });
        
      const consecutiveBurden = consecutive.map(item => item.LoadId);
      const brudenNumbers = await getSearchConsecutive({
      variables: { consecutiveBurden: consecutiveBurden}
      })

      let loads;
      if (brudenNumbers.data.ordersTable.length > 0) {
        loads = _data.map(item => {
            const foundElement = brudenNumbers.data.ordersTable.find(element => element.consecutiveBurden === item.LoadId);
            return {
                ...item,
                orderNumber: foundElement ? foundElement.orderNumber : ""
            };
        });
       }else{
        loads = _data.map((item)=>{
          return({
            ...item,
            orderNumber: []
          })
        });
      }


        setLoadsData(loads,count);      
        const data = loads
          .map((item) => { 
            let status = "Cargando";

              const lineQty = item.LoadLines?.filter((loadLine) => {
                if (orderConsecutiveShippingList?.includes(loadLine.ShipmentId)) {
                  return true;
                }
                return false;
              }).length;
              
              if(lineQty === item.LoadLines?.length){
                status = "Sincronizado";
              }else if(lineQty > 0){
                status = "Parcialmente Sincronizado";
              }else{
                status = item.LoadStatus;
              }

              let InvoiceQty = 0;
              let SlipShippingQty = 0;
              let LinesQty = 0;
              let ContainersQty = 0;

              let PaymentOnCashValue = 0;
              let PaymentOnCashValueCurrency = "";
              let PaymentMode = "";

              for (let i = 0; i < item.LoadLines.length; i++) {
                const line = item.LoadLines[i];
                InvoiceQty+= line.InvoiceList ? line.InvoiceList.length : 0;

                if(InvoiceQty > 0){
                  for (let j = 0; j < line.InvoiceList.length; j++) {
                    const invoice = line.InvoiceList[j];
                    PaymentMode = invoice.Payment;
                    if(invoice.Payment === "CONTRAENTREGA"){
                      PaymentOnCashValue+= invoice.InvoiceAmount;
                      PaymentOnCashValueCurrency = invoice.CurrencyCode;
                    }
                  }
                }

                SlipShippingQty+= line.PackingSlipList ? line.PackingSlipList.length : 0;
                LinesQty+= line.ItemList ? line.ItemList.length : 0;
                ContainersQty+= line.ContainerList ? line.ContainerList.length : 0;
              }

            return({
            ...item,
            key: item.LoadId,
            Type: item.LoadLines[0]?.Type ?? item.Type,
            LoadStatus: status,
            Order: status === "Enviados" ? 1 : status === "Cargado" ? 2 : status === "Parcialmente Sincronizado" ? 3 : 4,
            InvoiceQty,
            SlipShippingQty,
            LinesQty,
            ContainersQty,
            PaymentOnCashValue,
            PaymentOnCashValueCurrency,
            PaymentMode
          })})
          .sort((a, b) => {
            if (a.LoadShipConfirmUTCDateTime < b.LoadShipConfirmUTCDateTime) {
              return 1;
            }
            if (a.LoadShipConfirmUTCDateTime > b.LoadShipConfirmUTCDateTime) {
              return -1;
            }
            return 0;
          })
          .sort((a, b) => {
            if (a.Order < b.Order) {
              return -1;
            }
            if (a.Order > b.Order) {
              return 1;
            }
            return 0;
          });
        setLoadsDataRefresh(data);
        setLoads(data);
        setDataFilterLoad(data);
        setFilter("");
        setFilterTransport("");
        setFilterTransportOvTrn("");
        setLoading(false);
        setCount(count); 
        
        
      });
    } catch (error) {
      console.error(error);
    }
    
    if(searchText?.length > 0){
      setFilter(searchText);
      
    }
    if(transport?.length > 0){
      setFilterTransport(transport);
    }
    if(ovTrn?.length > 0){
      setFilterTransportOvTrn(ovTrn);
    }
   
    
  };
  
  
  const textFieldEventFilterUm = (ev) => {
    console.log(ev.target.value);
    if(ev.target.value === 0)
    {
      setValorBusqueda("");
    }
    else{
      setValorBusqueda(ev.target.value);
    }
    
};

  const searchFieldFilteresUm = () => {
  
    const fiterUmSearch = valorBusqueda;
    console.log(fiterUmSearch)
    const filterUm = fiterUmSearch.length > 0 ? load.filter((item) =>{
      if(item.orderNumber.includes(fiterUmSearch)){
        return true;
      }
  }): loadData;
  setLoads(filterUm);
  console.log(filterUm);
  console.log(load);
};

    /*
    console.log(ev.target.value);
    if (ev.target.value.length === 0) {

        console.log(ev.target.value.length);
        setLoads(loadData);
    } else if (ev.target.value.length > 0 && saveConsecutive == true) {
        
        const filterTms = load.filter((item) => item.orderNumber == ev.target.value);
        console.log(filterTms);
        setLoads(filterTms);
    }
    */    

  useEffect(() => {
    getDistributionCenters();
    getSectors();
    getOrderRunning();
  }, []);

  useEffect(() => {
    if(dataOrderRunning.data?.ordersTable?.length >= 0 && dataSectors.data?.sectors?.length >= 0 && dataDistributionCenters.data?.distributionCenters?.length >= 0){
      if(load?.length !== 0){
        getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], selectedPageSize, selectedPageId, filter, filterTransport, filterTransportOvTrn);
      }
    }
  }, [dataOrderRunning, dataSectors, dataDistributionCenters]);
  

  
  const toggleSearchTransportInvisible = () => {
    setSearchVisible(false);
  }
  const toggleSearchTransport = () => {
    setSearchVisible(!searchVisible);
  }
  const toggleSearchTransportInvisibleOvTrn = () => {
    setSearchVisibleOvTrn(false);
  }
  const toggleSearchTransportOvTrn = () => {
    setSearchVisibleOvTrn(true);
  }

  const toggleSearchInvisibleConsecutiveUm = () => {
    setSearchVisibleConsecutiveUm(false);
  }
  const toggleSearchConsecutiveUm = () => {
    setSearchVisibleConsecutiveUm(true);
  }

  
  const columns = [
    {
      key: "LoadShipConfirmUTCDateTime",
      dataIndex: "LoadShipConfirmUTCDateTime",
      title: "Fecha y Hora de Confirmacion de Envio",
      sorter: (a, b) =>
        a.LoadShipConfirmUTCDateTime.localeCompare(b.LoadShipConfirmUTCDateTime),
      render: (value) => {
          if(!value){
            return "N/D";
          }
    
          let date = moment.utc(value);
          let localTime = moment(date).subtract(5, 'hours').format('DD/MM/YYYY h:mm a');
    
          return <span>{localTime === "01/01/1900 12:00 am" || value === "1900-01-01T00:00:00Z" ? "N/D" : localTime}</span>;
      }
    },
    {
      key: "LoadStatus",
      dataIndex: "LoadStatus",
      title: "Estado",
      render: (_, { LoadStatus }) => (
        <Tag
          color={
            LoadStatus === "Sincronizado"
              ? "grey"
              : LoadStatus === "Cargado"
              ? "green"
              : "blue"
          }
          key={LoadStatus}
        >
          {LoadStatus.toUpperCase()}
        </Tag>
      ),
      sorter: (a, b) => a.LoadStatus.localeCompare(b.LoadStatus),
    },
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: (
        <span>
          {searchVisibleConsecutiveUm ? (
            <div>
              <span onClick={() => toggleSearchInvisibleConsecutiveUm()}>Consecutivo UM🔍</span>
             
            <TextField  onChange={textFieldEventFilterUm} label="Buscar" className="custom-filter" size={"large"}  color= "error"  InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton>
            {loading ? <CircularProgress size={10} color="error" /> :  <Search onClick={searchFieldFilteresUm}/>}
            </IconButton>
            </InputAdornment>
            }}/>
              
    
            </div> 
          ) : (
            <span onClick={() => toggleSearchConsecutiveUm()}>Consecutivo UM🔍</span>
          )}
        </span>
      ),
       render:(orderNumber) =>
      {
        if(orderNumber != "")
        {
          return orderNumber;
        }
        else{
          return "N/D";
        }
      }, 
    },
    {
      key: "LoadId",
      dataIndex: "LoadId",
      title: "Carga",
      sorter: (a, b) => a.LoadId.localeCompare(b.LoadId),
    },
    {
      key: "InventLocationId",
      dataIndex: "InventLocationId",
      title: "Bodega",
      sorter: (a, b) => a.InventLocationId.localeCompare(b.InventLocationId),
    },
    {
      key: "OrderNum",
      dataIndex: "OrderNum",
      title: (
        <span>
          {searchVisibleOvTrn ? (
            <div>
              <span onClick={() => toggleSearchTransportInvisibleOvTrn()}>Orden/TRN🔍</span>
              <TMSFiltersOvTrn isBlankActiveOvtm={isBlankActiveOvtm}/>
            </div> 
          ) : (
            <span onClick={() => toggleSearchTransportOvTrn()}>Orden/TRN🔍</span>
          )}
        </span>
      ),  
      render: (text, record) => {
       
        const orderNumToDisplay = record.OrderNum !== "" ? record.OrderNum : (record.LoadLines?.map(loadLine => loadLine.OrderNum) || []);
        if (Array.isArray(orderNumToDisplay) && orderNumToDisplay.length > 0) {
          return orderNumToDisplay.join(' ');
        } else {
          return orderNumToDisplay;
        }
      },
    },
    {
      key: "WorkerSalesResponsible",
      dataIndex: "WorkerSalesResponsible",
      title: "Nombre Asesor",
      render:(WorkerSalesResponsible) =>
      {
        if(WorkerSalesResponsible != "")
        {
          return WorkerSalesResponsible;
        }
        else{
          return "N/D";
        }
      },
      sorter: (a, b) =>
        a.WorkerSalesResponsible.localeCompare(b.WorkerSalesResponsible),
        
    },
    {
      key: "CarrierCode",
      dataIndex: "CarrierCode",
      title: (
        <span>
          {searchVisible ? (
            <div>
              <span onClick={() => toggleSearchTransportInvisible()}>Transportista🔍</span>
              <TMSFiltersTransport />
            </div>
          ) : (
            <span onClick={() => toggleSearchTransport()}>Transportista🔍</span>
          )}
        </span>
      ),
     
      
    },
    {
      key: "CarrierServiceCode",
      dataIndex: "CarrierServiceCode",
      title: "Servicio",
      sorter: (a, b) =>
        a.CarrierServiceCode.localeCompare(b.CarrierServiceCode),
    },
    {
      key: "DlvMode",
      dataIndex: "DlvMode",
      title: "Modo de Entrega",
      sorter: (a, b) =>
        a.DlvMode.localeCompare(b.DlvMode),
    },
    {
      key: "PaymentMode",
      dataIndex: "PaymentMode",
      title: "Forma de Pago",
      sorter: (a, b) =>
        a.PaymentMode.localeCompare(b.PaymentMode),
    },
    {
      key: "PaymentOnCashValue",
      dataIndex: "PaymentOnCashValue",
      title: "Valor a Pagar Contraentrega",
      sorter: (a, b) =>
        a.PaymentOnCashValue - b.PaymentOnCashValue,
    },
    {
      key: "PaymentOnCashValueCurrency",
      dataIndex: "PaymentOnCashValueCurrency",
      title: "Moneda",
      sorter: (a, b) =>
        a.PaymentOnCashValueCurrency.localeCompare(b.PaymentOnCashValueCurrency),
    },
    {
      key: "InvoiceQty",
      dataIndex: "InvoiceQty",
      title: "Facturas",
      sorter: (a, b) =>
      a.InvoiceQty - b.InvoiceQty,
    },
    {
      key: "SlipShippingQty",
      dataIndex: "SlipShippingQty",
      title: "Remisiones",
      sorter: (a, b) =>
        a.SlipShippingQty - b.SlipShippingQty,
    },
    {
      key: "LinesQty",
      dataIndex: "LinesQty",
      title: "Lineas",
      sorter: (a, b) =>
        a.LinesQty - b.LinesQty,
    },
    {
      key: "ContainersQty",
      dataIndex: "ContainersQty",
      title: "Contenedores",
      sorter: (a, b) =>
        a.ContainersQty - b.ContainersQty,
    },
    {
      key: "Type",
      dataIndex: "Type",
      title: "Tipo",
      sorter: (a, b) =>
        a.Type.localeCompare(b.Type),
    },
  ];

 
const handleBack = () => {
     history.replace("/main-upload");
     setSearchTextOvTrn("");
     setSearchText(''); 
    
    };

    const hadleBlanck = () => {
     setSearchTextOvTrn('');
     setSearchFilterOvTrn('');
     setSearchText('');  
     setSearchFilter('');
     setIsBlankActive(true);
     setIsBlankActiveOvtm(true);
     

     setTimeout(() => {
      setIsBlankActive(false);
      setIsBlankActiveOvtm(false);

    }, 1000);
    };

  const handlePageChange = (page, pageSize) => {
    const validationfilterOvTrn = filterTransportOvTrn !== "" ? filterTransportOvTrn : "";
      getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], pageSize, page, "", filterTransport, validationfilterOvTrn); 
      setLoads();
      setSelectedPageId(page);
      setSelectedPageSize(pageSize);
  };
  
  return (
    
    <Col className="children-inner-container-with-bg">
      {initLoading ? (<CircularProgress size={60} thickness={5} color={"error"} className="tms-loading" />) 
      : (
      <div>
       <Button
      style={{ marginTop: 10 }}
      type="primary"
      shape="round"
      ghost={true}
      className="children-inner-button"
      icon={<ArrowLeftOutlined />}
      onClick={handleBack}
      ></Button>
      
      <Button
        type="primary"
        shape="round"
        ghost={true}
        className="children-inner-button-refresh"
        icon={<RedoOutlined />}
        onClick={hadleBlanck}
        
      ></Button>
      {selecteLoad ? (
        <Row></Row>
      ) : (
        <Col className="children-inner-container-with-bg">
        <div> 
          <h1 className="titulo">Todas las cargas</h1>
          <Row className="children-inner-row">
            <TMSFiltersCarga isBlankActive={isBlankActive}/>
          </Row>
          <br />
          <Row>
            <Table
              className="children-table2"
              loading={loading}
              columns={columns}
              dataSource={load}
              sle
              pagination={false}
              rowKey="key"
              onRow={(record) => {
                return {
                  onClick: () => {
                    setSelecteLoad(record);
                  },
                };
              }}
            />
             <Pagination
              current={selectedPageId}
              pageSize={selectedPageSize}
              total={count}
              onChange={handlePageChange}
              pageSizeOptions={[10,20,50,100,200,500]}
              showSizeChanger={true}
            />
          </Row>
          </div> 
          </Col>
      )}
      {selecteLoad ? (
        <TMSShipmentScreen
          selecteLoad={selecteLoad}
          setSelecteLoad={setSelecteLoad}
          getOrderRunning={getOrderRunning}
          dataOrderRunning={dataOrderRunning}
          setLoads={setLoads}
          getTMSLoad={getTMSLoad}
          dataOrderRunningg={dataOrderRunning.data?.ordersTable}
          CenterUser={CenterUser}
          sectors={dataSectors.data?.sectors}
          distributionCenters={dataDistributionCenters.data?.distributionCenters}
          selectedPageSize={selectedPageSize} 
          selectedPageId = {selectedPageId} 
          filterr = {filter}
        />
      ) : (
        <Row></Row>
      )}
      </div>
    )}
    </Col>
  );
};
