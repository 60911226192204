import { gql } from "@apollo/client";

export const fetchCompletedRoutes = gql`
query fetchCompletedRoutes($distributionCenterId: [uuid!], $limit: Int, $offset: Int) {
  routesBatch(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}, limit: $limit, offset: $offset) {     
      id
      batch
      createdAt
      startDateRoute
      endDateRoute
      batch
      user {
        id
          identificationNumber
          displayName
          distributionCenterId
          roleId
         userDeliveryCenters{
          distributionCenterId
        }
      }
      licencePlate
      routes(order_by: {endDateTime: desc}) {
          id
          status
          startDateTime
          endDateTime
          userId
          distanceText
          distanceValue
          durationText
          durationValue
          receivedPerson
          receivedDocument
          polylines
          batch
      		createByUser{
            displayName
          }
          user {
              displayName
              identificationNumber
              phoneNumber
              userVehicle{
                  id
              }
          }
          orderTable {
              orderNumber
              address
              addressComplement
              custFullName
              custIdentificationNumber
              custPhoneNumber
              paymentMethod
              sectorId
              consecutiveBill
              consecutiveRemission
              consecutiveSaleOrder
              consecutiveBurden
              consecutiveShipping
              type
              state
              city
              expectedDeliveryStartTime
              expectedDeliveryEndTime
              notes
              sector {
                  name
              }
              totalOrderAmount
              weight
              evidences {
                  evidenceType
                  evidenceURL
                  comments
              }
              ordersLines {
                  id
                  productNumber
                  productName
                  orderedQuantity
                  deliveredQuantity
                  initialQuantity
                  summationQuantity
                }
          }
          orderTableId
          reasonId
          reason {
              description
          }
      }
  }
}
`;

export const FilterPlaniCompleteRoutes = gql`
query FilterPlaniCompleteRoutes($distributionCenterId: [uuid!], $displayName: String, $limit: Int, $offset: Int) {
  routesBatch(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{routes:{createByUser:{displayName: { _eq: $displayName}}}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}}, limit: $limit, offset: $offset) {     
      id
      batch
      createdAt
      startDateRoute
      endDateRoute
      batch
      user {
        id
          identificationNumber
          displayName
          distributionCenterId
          roleId
         userDeliveryCenters{
          distributionCenterId
        }
      }
      licencePlate
      routes(order_by: {endDateTime: desc}) {
          id
          status
          startDateTime
          endDateTime
          userId
          distanceText
          distanceValue
          durationText
          durationValue
          receivedPerson
          receivedDocument
          polylines
          batch
      		createByUser{
            displayName
          }
          user {
              displayName
              identificationNumber
              phoneNumber
              userVehicle{
                  id
              }
          }
          orderTable {
              orderNumber
              address
              addressComplement
              custFullName
              custIdentificationNumber
              custPhoneNumber
              paymentMethod
              sectorId
              consecutiveBill
              consecutiveRemission
              consecutiveSaleOrder
              consecutiveBurden
              consecutiveShipping
              type
              state
              city
              expectedDeliveryStartTime
              expectedDeliveryEndTime
              notes
              sector {
                  name
              }
              totalOrderAmount
              weight
              evidences {
                  evidenceType
                  evidenceURL
                  comments
              }
              ordersLines {
                  id
                  productNumber
                  productName
                  orderedQuantity
                  deliveredQuantity
                  initialQuantity
                  summationQuantity
                }
          }
          orderTableId
          reasonId
          reason {
              description
          }
      }
  }
}
`;

export const fetchCompletedRoutesDetails = gql`
query fetchCompletedRoutesDetails($distributionCenterId: [uuid!], $batch: uuid, $limit: Int, $offset: Int) {
  routesBatch(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{batch: {_eq: $batch}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}}, limit: $limit, offset: $offset) {     
      id
      batch
      createdAt
      startDateRoute
      endDateRoute
      batch
      user {
        id
          identificationNumber
          displayName
          distributionCenterId
          roleId
         userDeliveryCenters{
          distributionCenterId
        }
      }
      licencePlate
      routes(order_by: {endDateTime: desc}) {
          id
          status
          startDateTime
          endDateTime
          userId
          distanceText
          distanceValue
          durationText
          durationValue
          receivedPerson
          receivedDocument
          polylines
          batch
      		createByUser{
            displayName
          }
          user {
              displayName
              identificationNumber
              phoneNumber
              userVehicle{
                  id
              }
          }
          orderTable {
              orderNumber
              address
              addressComplement
              custFullName
              custIdentificationNumber
              custPhoneNumber
              paymentMethod
              sectorId
              consecutiveBill
              consecutiveRemission
              consecutiveSaleOrder
              consecutiveBurden
              consecutiveShipping
              type
              state
              city
              expectedDeliveryStartTime
              expectedDeliveryEndTime
              notes
              sector {
                  name
              }
              totalOrderAmount
              weight
              evidences {
                  evidenceType
                  evidenceURL
                  comments
              }
              ordersLines {
                  id
                  productNumber
                  productName
                  orderedQuantity
                  deliveredQuantity
                  initialQuantity
                  summationQuantity
                }
          }
          orderTableId
          reasonId
          reason {
              description
          }
      }
  }
}
`;

export const FilterDetailsCompleteRoutes = gql`
query FilterDetailsCompleteRoutes(
  $distributionCenterId: [uuid!], 
  $orderNumber: String, 
  $consecutiveBurden: String, 
  $consecutiveSaleOrder: String, 
  $limit: Int, 
  $offset: Int
) {
  routesBatch(
    order_by: {endDateRoute: desc}, 
    where: {
      user: {
        userDeliveryCenters: {
          distributionCenterId: { _in: $distributionCenterId }
        }
      }, 
      _and: [
        {
          routes: {
            orderTable: {
              _or: [
                {orderNumber: { _eq: $orderNumber }},
                {consecutiveBurden: { _eq: $consecutiveBurden }},
                {consecutiveSaleOrder: { _eq: $consecutiveSaleOrder }}
              ]
            }
          }
        },
        {startDateRoute: {_is_null: false}},
        {endDateRoute: {_is_null: false}}
      ]
    }, 
    limit: $limit, 
    offset: $offset
  ) {     
    id
    batch
    createdAt
    startDateRoute
    endDateRoute
    user {
      id
      identificationNumber
      displayName
      distributionCenterId
      roleId
      userDeliveryCenters{
        distributionCenterId
      }
    }
    licencePlate
    routes(order_by: {endDateTime: desc}) {
      id
      status
      startDateTime
      endDateTime
      userId
      distanceText
      distanceValue
      durationText
      durationValue
      receivedPerson
      receivedDocument
      polylines
      batch
      createByUser{
        displayName
      }
      user {
        displayName
        identificationNumber
        phoneNumber
        userVehicle{
          id
        }
      }
      orderTable {
        orderNumber
        address
        addressComplement
        custFullName
        custIdentificationNumber
        custPhoneNumber
        paymentMethod
        sectorId
        consecutiveBill
        consecutiveRemission
        consecutiveSaleOrder
        consecutiveBurden
        consecutiveShipping
        type
        state
        city
        expectedDeliveryStartTime
        expectedDeliveryEndTime
        notes
        sector {
          name
        }
        totalOrderAmount
        weight
        evidences {
          evidenceType
          evidenceURL
          comments
        }
        ordersLines {
          id
          productNumber
          productName
          orderedQuantity
          deliveredQuantity
          initialQuantity
          summationQuantity
        }
      }
      orderTableId
      reasonId
      reason {
        description
      }
    }
  }
}
`;

export const CountFilterPlaniCompleteRoutes = gql`
query CountFilterPlaniCompleteRoutes($distributionCenterId: [uuid!],$displayName: String) {
  routesBatch_aggregate(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{routes:{createByUser:{displayName: { _eq: $displayName}}}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}}) {
    aggregate {
      count
    }
  }
}
`;

export const CountFilterCompleteRoutesDetails = gql`
query CountFilterCompleteRoutesDetails($distributionCenterId: [uuid!],$batch: uuid) {
  routesBatch_aggregate(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{batch: {_eq: $batch}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}}) {
    aggregate {
      count
    }
  }
}
`;

export const fetchtelemetry = gql`
query fetchtelemetry($userVehicleId: uuid, $startDateTime: timestamptz, $endDateTime: timestamptz) {
  telemetry(order_by: {timestamp: asc},where: {userVehicleId: { _eq: $userVehicleId },timestamp: {_gte: $startDateTime, _lte: $endDateTime}}) {
    id
    userVehicleId
    latitude
    longitude
    timestamp
    speed
    distance
    userVehicle {
      id
      assignedRoutes {
        id
        startDateTime
        endDateTime
        orderTable{
          id
          orderNumber
        }
      }
    }
  }
}
`;
export const dataRoutesForRouteBatch = gql`
query fetchRoutes($distributionCenterId: [uuid!]) {
  routes(order_by: {endDateTime: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{routeBatch: {startDateRoute: {_is_null: false}}, _and:{routeBatch:{endDateRoute: {_is_null: false}}}}}) {
          id
          status
          startDateTime
          endDateTime
          userId
          distanceText
          distanceValue
          durationText
          durationValue
          receivedPerson
          receivedDocument
          polylines
          batch
      		createByUser{
            displayName
          }
          user {
              displayName
              identificationNumber
              phoneNumber
              userVehicle{
                  id
              }
          }
          orderTable {
              orderNumber
              address
              addressComplement
              custFullName
              custIdentificationNumber
              custPhoneNumber
              paymentMethod
              sectorId
              consecutiveBill
              consecutiveRemission
              consecutiveSaleOrder
              consecutiveBurden
              consecutiveShipping
              type
              state
              city
              expectedDeliveryStartTime
              expectedDeliveryEndTime
              notes
              sector {
                  name
              }
              totalOrderAmount
              weight
              evidences {
                  evidenceType
                  evidenceURL
                  comments
              }
              ordersLines {
                  id
                  productNumber
                  productName
                  orderedQuantity
                  deliveredQuantity
                  initialQuantity
                  summationQuantity
                }
          }
          orderTableId
          reasonId
          reason {
              description
          }
      }
  }
`;

export const countRegisterRoute = gql`
query countRegisterRoute($distributionCenterId: [uuid!]) {
  routesBatch_aggregate(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}) {
    aggregate {
      count
    }
  }
}
`;

export const countRegisterRouteNumber = gql`
query countRegisterRouteNumber($distributionCenterId: [uuid!]) {
  routesBatch(where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}}) {
    user {      
      userDeliveryCenters {
        distributionCenterId
      }
    }
  }
}
`;




